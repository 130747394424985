import React, { useEffect, useRef, useState } from 'react';
import { View, Flex, TextArea, Link, Button } from '@adobe/react-spectrum';
import { MenuItemModel, ContextMenuComponent } from '@syncfusion/ej2-react-navigations';
import { RatingComponent } from '@syncfusion/ej2-react-inputs';
import styles from '../ExternalContacts.module.css';
interface ContactCardProps {
  contact: any;
  avatarSrc: string;
  selectedItem: any;
  FPADataTypes: any;
  onSaveDescription: (contactId: string, description: string) => void;

  onSaveRating: (contactId: string, rating: string) => void;
}

const ContactCard: React.FC<ContactCardProps> = ({ contact, avatarSrc, selectedItem, FPADataTypes, onSaveDescription, onSaveRating }) => {
  const contextMenuRef = useRef<ContextMenuComponent>(null);
  const [isTextAreaFocused, setIsTextAreaFocused] = useState<boolean>(false);
  const [description, setDescription] = useState<string>(contact?.description);
  let menuItems: MenuItemModel[] = [
    {
      iconCss: 'bi bi-envelope',
      text: 'Copy email',
    },
    {
      iconCss: 'bi bi-phone',
      text: 'SMS',
    },
    {
      iconCss: 'bi bi-telephone',
      text: 'Call',
    },
  ];

  useEffect(() => {
    //console.log('contact', contact);
    // Add the click event listener to the target element
    //console.log('contact?.relationshipLevel', contact?.relationshipLevel);
    const targetElement = document.querySelector(`.context-target_${contact.id}`);
    if (targetElement) {
      targetElement.addEventListener('click', handleContextMenu);
    }
    return () => {
      if (targetElement) {
        targetElement.removeEventListener('click', handleContextMenu);
      }
    };
  }, []);

  const handleContextMenu = (event: any) => {
    // Prevent the default context menu
    event.preventDefault();

    // Open the custom context menu at the click position
    if (contextMenuRef.current) {
      contextMenuRef.current.open(event.pageY, event.pageX);
    }
  };

  const handleMenuItemClick = (args: any) => {
    if (args?.item?.text === 'Print') {
      console.log('Print clicked');
    } else if (args?.item?.text === 'Edit') {
      console.log('Edit clicked');
    } else if (args?.item?.text === 'Delete') {
      console.log('Delete clicked');
    }
  };

  // Calculate the top position based on the presence of email, phone, and mobile
  const topPosition = (() => {
    let position = 0;
    if (contact?.email) position += 60;
    if (contact?.phone) position += 60;
    if (contact?.mobile) position += 60;
    return position === 0 ? 60 : Math.min(position, 125); // Ensure the max top value is 118
  })();

  const handleSaveDescription = () => {
    onSaveDescription(contact.id, description);
    //console.log('description', description);
    setIsTextAreaFocused(false);
  };

  function emptyTemplate() {
    return <span className="custom-font sf-icon-empty-star"></span>;
  }
  function fullTemplate() {
    return <span className="custom-font sf-icon-fill-star"></span>;
  }

  const handleRatingChange = (e: any) => {
    //console.log('Rating changed to:', e.value);
    onSaveRating(contact.id, String(e.value * 20));
  };

  return (
    <View key={contact.id} UNSAFE_className={styles.contact_parent}>
      <View UNSAFE_className={styles.contact_avatar_parent}>
        <img className={styles.contact_avatar} src={avatarSrc} alt="" />
      </View>
      <View UNSAFE_className={styles.contact_name}>
        {contact?.isPrimary === '1' && (
          <i className="bi bi-star-fill" style={{ color: '#F4CA16' }}>
            &nbsp;
          </i>
        )}

        {contact?.name3 + ' ' + contact?.name1}
      </View>
      <Flex UNSAFE_className={styles.description_parent} UNSAFE_style={{ top: `${topPosition}px` }}>
        <Flex direction={'row'} alignItems={'center'} justifyContent={'start'} alignSelf={'stretch'} height={'24px'}>
          <Flex direction={'row'} alignItems={'center'} justifyContent={'start'} height={'24px'}>
            <View position={'relative'}>Description</View>
          </Flex>
        </Flex>
        <TextArea defaultValue={contact?.note} value={description} onChange={e => setDescription(e)} onFocus={() => setIsTextAreaFocused(true)} width={'100%'}></TextArea>
        {isTextAreaFocused && (
          <Flex UNSAFE_className={styles.activity_btn_container}>
            <Flex UNSAFE_className={styles.activity_btn_master}>
              <Flex UNSAFE_className={styles.activity_btn_child}>
                <Flex UNSAFE_className={styles.btn_cancel_desc}>
                  <i
                    role="button"
                    tabIndex={0}
                    onClick={() => {
                      setIsTextAreaFocused(false);
                    }}
                    className={`${styles.icon_cancel} bi bi-x fs-3`}
                  ></i>
                </Flex>
                <Flex UNSAFE_className={styles.btn_save_desc}>
                  <i
                    role="button"
                    onClick={() => {
                      handleSaveDescription();
                    }}
                    className={`${styles.icon_add} bi bi-check fs-3`}
                  ></i>
                </Flex>
              </Flex>
            </Flex>
          </Flex>
        )}
      </Flex>
      <View UNSAFE_className={styles.icon_more}>
        <ContextMenuComponent target={`.context-target_${contact.id}`} id={`contextmenu_${contact.id}`} ref={contextMenuRef} items={menuItems} select={handleMenuItemClick} />
        <span className={`context-target_${contact.id}`} role="button" tabIndex={0}>
          <i className="bi bi-three-dots fs-7"></i>
        </span>
      </View>
      <Flex UNSAFE_className={styles.contact_details_parent}>
        {contact?.email && (
          <Flex UNSAFE_className={styles.icon_parent}>
            <i className={`${styles.icon_email} bi bi-envelope fs-7`}></i>
            <View position={'relative'}>{contact?.email}</View>
          </Flex>
        )}
        {contact?.phone && (
          <Flex UNSAFE_className={styles.icon_parent}>
            <i className={`${styles.icon_telephone} bi bi-telephone fs-7`}></i>
            <View position={'relative'}>{contact?.phone}</View>
          </Flex>
        )}
        {contact?.mobile && (
          <Flex UNSAFE_className={styles.icon_parent}>
            <i className={`${styles.icon_mobile} bi bi-phone fs-7`}></i>
            <View position={'relative'}>{contact?.mobile}</View>
          </Flex>
        )}
        {contact?.relationshipLevel && selectedItem.type === FPADataTypes.FOLDER && (
          <Flex UNSAFE_className={styles.icon_parent}>
            <RatingComponent emptyTemplate={emptyTemplate} fullTemplate={fullTemplate} value={contact?.relationshipLevel / 20} valueChanged={handleRatingChange}></RatingComponent>
          </Flex>
        )}
      </Flex>
    </View>
  );
};

export default ContactCard;
