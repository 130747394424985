import { useCallback, useEffect, useRef, useState } from 'react';
import { LayoutComponent } from '../LayoutComponent';
import { Button, Content, Flex, Heading, IllustratedMessage, View } from '@adobe/react-spectrum';
import Unavailable from '@spectrum-icons/illustrations/Unavailable';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../state/store';
import { FPAData, FPADataTypes } from '../../../../infra/protected/FPA/FPAData';
import { useDependency } from '../../../../contexts/DependencyProvider';
import { GetActivityRequest } from '../../../../services/soap/project/requests/GetActivityRequest';
import { GetProjectRequest } from '../../../../services/soap/project/requests/GetProjectRequest';
import { GetFolderRequest } from '../../../../services/soap/project/requests/GetFolderRequest';
import { ListFormsRequest } from '../../../../services/soap/form/requests/ListFormsRequest';
import { FormDefinition } from './components/FormDefinitions';
import { SoapUtils } from '../../../../utils/SoapUtils';
import { FormControl } from './components/FormControl';
import { ListItemValueRequest } from '../../../../services/soap/form/requests/ListItemValueRequest';
import { Rows } from '../../../../services/soap/form/responses/ListItemValueResponse';
import { ItemValue, UpdateItemValueRequest } from '../../../../services/soap/form/requests/UpdateItemValueRequest';
import { ToastQueue } from '@react-spectrum/toast';
import { GetFolderProjectTypeRequest } from '../../../../services/soap/project/requests/GetFolderProjectTypeRequest';

export interface IFormViewProps {
    formIdType?: string;
    formId?: string;
    formIdMode?: string;
    isSaveEnabled?: boolean;
    isSaveOnRightSide?: boolean;    
}

function FormView ({
    formIdType,
    formId,
    formIdMode,
    isSaveEnabled,
    isSaveOnRightSide
}: IFormViewProps) {
  const selectedItem = useSelector((state: RootState) => state.finder.selectedItem);

  const { 
    formService,
    projectService, 
    store 
  } = useDependency();

  const [formDefinition, setFormDefinition] = useState<FormDefinition|null>(null);
  const [formData, setFormData] = useState<Map<string, any>>(new Map<string, any>());
  const realFormId = useRef('0');

  var self: FPAData | undefined | null = null;

  switch(formIdType) {
    case 'self':
      self = selectedItem;
      break;
    case 'project':
      self = selectedItem;
      while(self && self.type !== FPADataTypes.PROJECT) {
        self = self.parent;
      };
      break;
    case 'folder':
      self = selectedItem;
      while(self && self.type !== FPADataTypes.FOLDER) {
        self = self.parent;
      };
      break;
  }


  const handleSaveForm = async () => {
      const valuesArray = Array.from(formData.values());

      const passed_values = valuesArray.map((value: any) => {
        return new ItemValue( value.id, value.acl, value.formInfo, value.formItem, value.formItemName, value.value, value.typ, value.context);
      });

      var save_response = await formService.updateItemValue(new UpdateItemValueRequest(store.Server, store.SessionId, passed_values));
      if(save_response.result === 'OK') {
        ToastQueue.positive('Form saved successfully', { timeout: 3000 });
      } else {
        ToastQueue.negative('Error saving form', { timeout: 3000 });
      }
  };

  const loadFormCallback = useCallback(async () => {
    if(self === null || self === undefined) return;
    if(self.type === FPADataTypes.FOLDER_TYPE || self.type === FPADataTypes.ITEM_TYPE) return;
    
    var form_id = null; 
    var formValuesId = null;
    
    switch(self.type) {
        case FPADataTypes.ACTIVITY:
          var activity = (await projectService.getActivity(new GetActivityRequest(store.Server, store.SessionId, self.id))).ACTIVITY;
          form_id = formIdMode === 'template' ? activity.formId : formId;
          formValuesId = activity.formValues;
          break;
        case FPADataTypes.PROJECT:
          var project = (await projectService.getProject(new GetProjectRequest(store.Server, store.SessionId, self.id))).PROJECT;
          form_id = formIdMode === 'template' ? project.formId : formId;
          formValuesId = project.formValues;
          break;
        case FPADataTypes.FOLDER:
          var folder = (await projectService.getFolder(new GetFolderRequest(store.Server, store.SessionId, self.id))).FOLDER;
          form_id = formIdMode === 'template' ? folder.formId : formId;
          formValuesId = folder.formValues;
          break;
    }

    if(formIdMode === 'type'){
      var type_info = await projectService.getFolderProjectType(new GetFolderProjectTypeRequest(store.Server, store.SessionId, self.item_type_id));
      form_id = type_info.TYPE.formId;
    }

    if(!form_id) return;

    realFormId.current = formValuesId!;
    var form = await formService.listForms(new ListFormsRequest(store.Server, store.SessionId, form_id!));

    var jsonObject = SoapUtils.parseXmlString2(form.ROW.definition, ['COMPONENT']);

    var formValues = await formService.listItemValue(new ListItemValueRequest(store.Server, store.SessionId, formValuesId!));
    setFormDefinition(jsonObject);
    
    if(formValues.count > 0) {
      var valuesMap = formValues.ROWS.reduce((acc: Map<string, any>, row: Rows) => { 
        acc.set(row.ITEMVALUE.formItem, row.ITEMVALUE);              
        return acc
      }, new Map<string, any>());
      setFormData(valuesMap);
    }

  }, [self]);

  const updateData = (key: string, value: any) => {
    console.log(`${key}: ${value}`);
    var modified_item = null;
    if(formData.has(key)) {
      modified_item = formData.get(key);
      modified_item.value = value;
    }else{
      modified_item = {
        value: value,
        id: null,
        acl: '',
        formInfo: realFormId.current,
        formItem: key,
        formItemName: '',
        typ: '',
        context: ''
      }
    }

    setFormData(new Map(formData.set(key, modified_item)));
  };

  const getData = (key: string, default_value: any) => {
    var item = formData.get(key);
    return formData.has(key) && item.value ? item.value : default_value;
  }
  useEffect(() => {
    loadFormCallback();
  }, [loadFormCallback]);

  if(formDefinition === null)
    return (
      <>
        <IllustratedMessage>
          <Unavailable />
          <Heading>Unable to show form</Heading>
          <Content>
          Form was not configured for this view.
          </Content>

        </IllustratedMessage>
      </>
    );
  else if (!formDefinition.definition || !formDefinition.definition.gui || !formDefinition.definition.gui.COMPONENT)
    return (
      <>
       
      </>
    );
  else
    return (
      <>
      <Flex direction={'column'} gap={10}>
        <View>
          <FormControl component={formDefinition.definition.gui.COMPONENT[0]} getData={getData} updateData={updateData} />
        </View>
        {isSaveEnabled && (<View alignSelf={isSaveOnRightSide ? 'end' : 'start'}>
          <Button variant="cta" onPress={() => handleSaveForm()}>Save</Button>
        </View>)}
      </Flex>
      </>
    );
}

export const FormViewComponent = LayoutComponent(FormView);