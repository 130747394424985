import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDependency } from '../../../../contexts/DependencyProvider';
import { ListStatusRequest, ListStatusType } from '../../../../services/soap/project/requests/ListStatusRequest';
import { Flex, View } from '@adobe/react-spectrum';
import { enableRipple } from '@syncfusion/ej2-base';
import { ContextMenuComponent, MenuItemModel } from '@syncfusion/ej2-react-navigations';
import { AtollonIcon, MoreIcon, ProjectIconBlue } from '../../Icons/IconsLib';
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import { GetContextPathRequest } from '../../../../services/soap/project/requests/GetContextPathRequest';
import styles from './HeaderToolbarComponent.module.css';

interface IProjectComponentMobileProps {
  headerData: any;
  statusColor: (color: string) => string;
  menuItems: MenuItemModel[];

  handleMenuItemClick?: (args: any) => void;
  onAIClicked?: () => void;
}
enableRipple(true);
const ProjectComponentMobile = ({ headerData, statusColor, menuItems, handleMenuItemClick, onAIClicked }: IProjectComponentMobileProps) => {
  const { store, projectService } = useDependency();
  const [projectStatusList, setProjectStatusList] = useState<any>([]);
  const [fullPath, setFullPath] = useState<string>('');
  const [projectTypeName, setProjectTypeName] = useState<string>('');
  const contextMenuRef = useRef<ContextMenuComponent | null>(null);

  useEffect(() => {
    (async () => {
      headerData && (await getFullPath());
      await getProjectStatusArray();
    })();
  }, []);

  React.useEffect(() => {
    // Add the click event listener to the target element
    const targetElement = document.querySelector('.context-target');
    if (targetElement) {
      targetElement.addEventListener('click', handleContextMenu);
    }

    // Cleanup
    return () => {
      if (targetElement) {
        targetElement.removeEventListener('click', handleContextMenu);
      }
    };
  }, []);
  const getFullPath = async () => {
    const response = await projectService.getContextPath(new GetContextPathRequest(store.Server, store.SessionId, headerData?.id || 0));
    setProjectTypeName(response.LIST[0].CONTEXT?.typeName);
    const modifiedList = response.LIST.slice(1).reverse();
    setFullPath(modifiedList.map(item => item.CONTEXT.name).join(' > '));
  };

  const getProjectStatusArray = useCallback(async () => {
    const statusArray: any = [];
    let res: any = await projectService.listProjectStatus2(new ListStatusRequest(store.Server, store.SessionId, ListStatusType.Project));

    if (res.result === 'OK' && res.STATUS.length > 0) {

      res?.STATUS.forEach((status: any) => {
        // Extract id and name from each STATUS object
        const { id, name, color, TYPE } = status;
        // Push an object containing id and name to the statusArray
        statusArray.push({ id, name, color, types: TYPE });
      });

      var filtered_status = statusArray.filter((status: any) => status.types.find( (type:any) => type.id === headerData?.projectType));

      setProjectStatusList(filtered_status);
    }
  }, []);

  function itemTemplate(data: any): JSX.Element {
    return (
      <Flex UNSAFE_className={styles.dot_text_container}>
        <View UNSAFE_className={styles.dot} UNSAFE_style={{ backgroundColor: `${statusColor(data.color)}` }}></View>
        <View marginTop={5}>{data.name}</View>
      </Flex>
    );
  }
  function valueTemplate(data: any): JSX.Element {
    return (
      <Flex UNSAFE_className={styles.dot_text_container}>
        <View UNSAFE_className={styles.dot} UNSAFE_style={{ backgroundColor: `${statusColor(data.color)}` }}></View>
        <View marginTop={5} UNSAFE_style={{ marginLeft: '10px' }}>
          <span title={data?.name}>{data?.name && data?.name.length > 23 ? data?.name.substring(0, 23) + '...' : data?.name}</span>
        </View>
      </Flex>
    );
  }

  const handleContextMenu = (event: any) => {
    // Prevent the default context menu
    event.preventDefault();

    // Open the custom context menu at the click position
    if (contextMenuRef.current) {
      contextMenuRef.current.open(event.pageY, event.pageX);
    }
  };
  const handleAIClicked = () => {
    if (onAIClicked) {
      onAIClicked();
    }
  };

  return (
    <Flex UNSAFE_className={styles.header}>
      <View UNSAFE_className={styles.header_main}>
        <Flex direction="row" width={'100%'} justifyContent={'space-between'}>
          <View>
            <DropDownListComponent
              width={'250px'}
              dataSource={projectStatusList}
              fields={{ text: 'name', value: 'id' }}
              showClearButton={true}
              itemTemplate={itemTemplate}
              valueTemplate={valueTemplate}
              value={headerData?.customState}
            />
          </View>
          <Flex direction={'row'}>
            <View>
              <div onClick={() => handleAIClicked()}>
                <AtollonIcon size={32} />
              </div>
            </View>
            <View>
              <ContextMenuComponent target=".context-target" id="contextmenu" ref={contextMenuRef} items={menuItems} select={handleMenuItemClick} />
              <span className="context-target">
                <MoreIcon size={32} />
              </span>
            </View>
          </Flex>
        </Flex>
        <View UNSAFE_className={styles.header_child} marginTop="15px">
          <View UNSAFE_className={styles.frame_parent}>
            <Flex direction={'row'} justifyContent={'space-between'} width={'100%'}>
              <View UNSAFE_style={{ display: 'flex', flexDirection: 'row', gap: '10px' }}>
                <View>
                  <ProjectIconBlue size={32} />
                </View>
                <View>
                  <b title={headerData?.name} className={styles.activity_name}>
                    {headerData?.name.length > 30 ? headerData?.name.substring(0, 30) + '...' : headerData?.name}
                  </b>
                </View>
              </View>
            </Flex>
            <View UNSAFE_className={styles.folder_name_parent}>
              <View UNSAFE_className={styles.folder_name}>{fullPath}</View>
            </View>
            <View UNSAFE_className={styles.tag_parent} marginTop={'5px'}>
              <Flex direction={'row'} justifyContent={'space-between'} width={'100%'}>
                <View UNSAFE_className={styles.tag}>
                  <View UNSAFE_className={styles.activity_type}>
                    <View UNSAFE_className={styles.activity_type_text}>{projectTypeName}</View>
                    {headerData?.refId ? (
                      <>
                        <View UNSAFE_className={styles.divider}></View> <View UNSAFE_className={styles.ref_id}>{headerData?.refId}</View>
                      </>
                    ) : (
                      <View UNSAFE_className={styles.divider_transparent}></View>
                    )}
                  </View>
                </View>
              </Flex>
            </View>
          </View>
        </View>
      </View>
    </Flex>
  );
};

export default ProjectComponentMobile;
