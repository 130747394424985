import React from "react";
import { IStore } from "../services/LocalStorage/IStore";
import { IMainService } from "../services/soap/main/MainService";
import { IFeatureService } from "../services/soap/features/FeatureService";
import { IProjectService } from "../services/soap/project/ProjectService";
import { IRecruitmentService } from "../services/soap/recruitment/RecruitmentService";
import { IRecruitmentRestService } from "../services/rest/recruitment/RecruitmentRestService";
import { IAccessService } from "../services/soap/access/AccessService";
import { IItemService } from "../services/soap/item/ItemService";
import { IFormService } from "../services/soap/form/FormService";
import { ITreeService } from "../services/soap/tree/TreeService";
import { ITranslateService } from "../services/soap/translate/TranslateService";
import { IFinanceService } from "../services/soap/finance/FinanceService";
import { IGlobalService } from "../services/soap/global/GlobalService";
import { ISchedulerService } from "../services/soap/scheduler/SchedulerService";
import { IPrintRestService } from "../services/rest/print/PrintRestService";
import { IDocumentLibraryService } from "../services/soap/document_library/DocumentLibraryService";
import { ITeamService } from "../services/soap/team/TeamService";

type DependencyContextProps = {
    accessService: IAccessService;
    mainService: IMainService;
    featureService: IFeatureService;
    projectService: IProjectService;
    recruitmentService: IRecruitmentService;
    itemService: IItemService;
    formService: IFormService;
    treeService: ITreeService;
    translateService: ITranslateService;
    financeService: IFinanceService;
    globalService: IGlobalService;
    schedulerService: ISchedulerService;
    documentLibraryService: IDocumentLibraryService;
    teamService: ITeamService;
    recruitmentRestService: IRecruitmentRestService;
    printRestService: IPrintRestService;
    store: IStore;
}
const DependencyContext = React.createContext<DependencyContextProps | undefined>(undefined);

export default DependencyContext;