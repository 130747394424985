import { AtollonEvent, publish } from "../infra/events/Events";
import { BrowserStorage } from "../services/LocalStorage/BrowserStorage";
import { IStore } from "../services/LocalStorage/IStore";
import { Application } from "../services/soap/features/responses/ListApplicationsResponse";

export const getAppIndent = (applications:Application[], id: string) => {
    var selected:Application[] = applications.filter((x) => x.id === id);
    return selected[0].appIdent;
};

export const getAtollonUrl = (appIdent: string | null):string => {
    if(!appIdent)
        return ''; 

    const store:IStore = new BrowserStorage();
    const prefix:string = 'atollon://';

    const customApps:string[] = store.customApps;
    const appUrls:Map<string, string> = new Map([
        ['com.atollon.messaging.messages', 'messaging/openCommunication'],
        ['com.atollon.finance.invoice.issued', 'finance/openInvoicesIssued'],
        ['com.atollon.finance.invoice.received', 'finance/openInvoicesReceived'],
        ['com.atollon.scheduler.callCenter.outgoing', 'scheduler/openCallCenter'],
        ['com.atollon.scheduler.events', 'scheduler/openCalendar'],
        ['com.atollon.scheduler.tasks', 'scheduler/openTasks'],
        ['com.atollon.lagoon.general.customApplication.serviceActivities', 'timesheet/openTimesheetGrid'],
        ['com.atollon.recruitment.dashboard', 'recruitment/openRecruitmentDashboard'],
        ['com.atollon.recruitment.foldertype.applicant', 'recruitment/openApplicants'],
        ['com.atollon.recruitment.vacancies', 'recruitment/openVacancies'],
        // ['', 'logger/show'],
        // ['', 'access/openUsersSettings']
    ]);


    if(customApps.includes(appIdent))
        return prefix  + 'customApplication/' + appIdent;

    if(appUrls.has(appIdent))
        return prefix + appUrls.get(appIdent);

    return '';
}

export const invokeFinderApp = (contextId: string, contextType: string = ''):void => {
    publish(AtollonEvent.FINDER_DETAIL_LOADED, { contextId, contextType });
}