import { BaseRequest, IBaseRequest } from '../../BaseRequest';

export interface IListDocumentRequest extends IBaseRequest {
    limit?:        string;
    offset?:       string;
    parent?:       string;
    orderBy?:      string;
    id?:           string;
    acl?:          string;
    countOnly?:    string;
    typeId?:       string;
    type?:         string;
    categoryId?:   string;
    category?:     string;
    deepSearch?:   string;
    PRIORITIES?:   string[];
    RESTRICTIONS?: string[];
}

export class ListDocumentRequest extends BaseRequest implements IListDocumentRequest {

    public limit?:        string = '';
    public offset?:       string = '';
    public parent?:       string = '';
    public orderBy?:      string = '';
    public id?:           string = '';
    public acl?:          string = '';
    public countOnly?:    string = '';
    public typeId?:       string = '';
    public type?:         string = '';
    public categoryId?:   string = '';
    public category?:     string = '';
    public deepSearch?:   string = '';
    public PRIORITIES?:   string[] = [];
    public RESTRICTIONS?: string[] = [];

    constructor(server: string, session: string, otherOptions: any) {
        super(server, session);
        this.limit = otherOptions.limit;
        this.offset = otherOptions.offset;
        this.parent = otherOptions.parent;
        this.orderBy = otherOptions.orderBy;
        this.id = otherOptions.id;
        this.acl = otherOptions.acl;
        this.countOnly = otherOptions.countOnly;
        this.typeId = otherOptions.typeId;
        this.type = otherOptions.type;
        this.categoryId = otherOptions.categoryId;
        this.category = otherOptions.category;
        this.deepSearch = otherOptions.deepSearch;
        this.PRIORITIES = otherOptions.PRIORITIES;
        this.RESTRICTIONS = otherOptions.RESTRICTIONS;
    }

    public toXml(): string {
        var parameters: string = super.toXml();

        if (this.limit) parameters += `<limit>${this.limit}</limit>`;
        if (this.offset) parameters += `<offset>${this.offset}</offset>`;
        if (this.parent) parameters += `<parent>${this.parent}</parent>`;
        if (this.orderBy) parameters += `<orderBy>${this.orderBy}</orderBy>`;
        if (this.id) parameters += `<id>${this.id}</id>`;
        if (this.acl) parameters += `<acl>${this.acl}</acl>`;
        if (this.countOnly) parameters += `<countOnly>${this.countOnly}</countOnly>`;
        if (this.typeId) parameters += `<typeId>${this.typeId}</typeId>`;
        if (this.type) parameters += `<type>${this.type}</type>`;
        if (this.categoryId) parameters += `<categoryId>${this.categoryId}</categoryId>`;
        if (this.category) parameters += `<category>${this.category}</category>`;
        if (this.deepSearch) parameters += `<deepSearch>${this.deepSearch}</deepSearch>`;
        if (this.PRIORITIES) parameters += this.PRIORITIES.map((priority) => `<PRIORITIES>${priority}</PRIORITIES>`).join('');
        if (this.RESTRICTIONS) parameters += this.RESTRICTIONS.map((restriction) => `<RESTRICTIONS>${restriction}</RESTRICTIONS>`).join('');
        
        return super.wrapperXml('ListDocument', parameters);
    }
}
