import { BaseRequest, IBaseRequest } from "../../BaseRequest";
import { Activity, toActivityXml } from "../../dataObjects/Activity";

export interface IUpdateActivityRequest extends IBaseRequest {
  activity: Activity;
}

export class UpdateActivityRequest
  extends BaseRequest
  implements IUpdateActivityRequest
{
  public activity: Activity;
  constructor(server: string, session: string, activity: Activity) {
    super(server, session);
    this.activity = activity;
  }

  public toXml(): string {
    var parameters: string = super.toXml();

    parameters += toActivityXml(this.activity);
    
    return super.wrapperXml("UpdateActivity", parameters);
  }
}
