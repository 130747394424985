import * as React from 'react';
import { LayoutComponent } from '../LayoutComponent';
import { Text } from '@adobe/react-spectrum';
import { title } from 'process';

export interface ITitleProps {
    minHeight?: number;
    percentWidth?: number;
    titleFontColor?: number;
    titleFontSize?: number;
    titleFontStyle?: string;
    titlePosition?: string;
    titleValue?: string;
}

function Title ({
    minHeight,
    percentWidth,
    titleFontColor,
    titleFontSize,
    titleFontStyle,
    titlePosition,
    titleValue
}: ITitleProps) {
    return (
        <>
            <div style={{
                minHeight: minHeight + 'px',
                width: percentWidth + '%',
                color: '#'+titleFontColor?.toString(16),
                fontSize: titleFontSize + 'px',
                fontStyle: titleFontStyle,
                textAlign: titlePosition === 'center' ? 'center' : titlePosition === 'right' ? 'right' : 'left'
            }}>
                {titleValue}
            </div>
        </>
    );
}

export const TitleComponent = LayoutComponent(Title);