import { BaseRequest, IBaseRequest } from '../../BaseRequest';

export interface IListDocumentCategoryRequest extends IBaseRequest {
    id?:        string;
    systemId?:  string;
}

export class ListDocumentCategoryRequest extends BaseRequest implements IListDocumentCategoryRequest {
    public id?:       string = '';
    public systemId?: string = '';

    constructor(server: string, session: string, id?: string, systemId?: string) {
        super(server, session);
        this.id = id;
        this.systemId = systemId;
    }

    public toXml(): string {
        var parameters: string = super.toXml();

        if (this.id) parameters += `<id>${this.id}</id>`;
        if (this.systemId) parameters += `<systemId>${this.systemId}</systemId>`;
        
        return super.wrapperXml('ListDocumentCategory', parameters);
    }
}
