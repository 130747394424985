import React from 'react';
import { ComboBox, Flex, Item, Link, TextArea, TextField, View } from '@adobe/react-spectrum';
import { useDependency } from '../../../../../contexts/DependencyProvider';
import { t } from 'i18next';
import { useViewInfo } from '../../../../../hooks/UseViewInfo';
import styles from './ExternalNewContacts.module.css';
import './ExternalContacts.css';

interface ExternalNewContactProps {
  openDialog: (isOpen: boolean) => void;
  closeDialog: (isOpen: boolean) => void;
}

const ExternalNewContact: React.FC<ExternalNewContactProps> = ({ openDialog, closeDialog }) => {
  const { isMobile } = useViewInfo();
  const { itemService, globalService, financeService, store } = useDependency();

  const BackContactButton = () => (
    <Flex UNSAFE_className={styles.button_container_new_contact_second}>
      <i className={`${styles.icon_add} bi bi-arrow-left fs-5`} />
      <Link isQuiet UNSAFE_className={styles.back_text} onPress={() => openDialog(true)}>
        Back
      </Link>
    </Flex>
  );

  const ContactActions = () => (
    <Flex UNSAFE_className={styles.button_container_new_contact}>
      <Flex UNSAFE_className={styles.new_contact_cancel}>
        <Link isQuiet UNSAFE_className={styles.new_contact_cancel_text} onPress={() => closeDialog(false)}>
          Cancel
        </Link>
      </Flex>
      <Flex UNSAFE_className={styles.new_contact_add}>
        <Link isQuiet UNSAFE_className={styles.new_contact_add_text}>
          Add Contact
        </Link>
      </Flex>
    </Flex>
  );

  const ContactButtons = () => (
    <Flex direction={isMobile ? 'column' : { base: 'column', L: 'row' }} justifyContent={'end'} gap={'size-100'}>
      <BackContactButton />
      <ContactActions />
    </Flex>
  );

  return (
    <Flex direction="column" width={'100%'} justifyContent={'center'} alignContent={'center'} UNSAFE_style={{ overflowY: 'auto' }}>
      <Flex direction="column" width={'100%'} justifyContent={'center'} alignContent={'center'}>
        <View UNSAFE_className={styles.add_contact_parent}>
          {/* <View UNSAFE_className={styles.add_client_contact_heading}>New Contact</View> */}
          <Flex UNSAFE_className="form_parent">
            <Flex UNSAFE_className="form_group">
              <Flex UNSAFE_className="form_fields">
                <Flex UNSAFE_className="form_input">
                  <Flex UNSAFE_className="form_label_container">
                    <Flex UNSAFE_className="form_label">
                      <View UNSAFE_className="form_label_text">Name</View>
                    </Flex>
                  </Flex>
                  <TextField width={'100%'}></TextField>
                </Flex>
                <Flex UNSAFE_className="form_input">
                  <Flex UNSAFE_className="form_label_container">
                    <Flex UNSAFE_className="form_label">
                      <View UNSAFE_className="form_label_text">Phone</View>
                    </Flex>
                  </Flex>
                  <TextField width={'100%'}></TextField>
                </Flex>
                <Flex UNSAFE_className="form_input">
                  <Flex UNSAFE_className="form_label_container">
                    <Flex UNSAFE_className="form_label">
                      <View UNSAFE_className="form_label_text">Email</View>
                    </Flex>
                  </Flex>
                  <TextField width={'100%'}></TextField>
                </Flex>
              </Flex>
              <Flex UNSAFE_className="form_fields">
                <Flex UNSAFE_className="form_input">
                  <Flex UNSAFE_className="form_label_container">
                    <Flex UNSAFE_className="form_label">
                      <View UNSAFE_className="form_label_text">Surname</View>
                    </Flex>
                  </Flex>
                  <TextField width={'100%'}></TextField>
                </Flex>
                <Flex UNSAFE_className="form_input">
                  <Flex UNSAFE_className="form_label_container">
                    <View UNSAFE_className="form_label_text">Country</View>
                  </Flex>
                  <ComboBox width={'100%'}>
                    <Item key="red panda">Red Panda</Item>
                  </ComboBox>
                </Flex>
                <Flex UNSAFE_className="form_input">
                  <Flex UNSAFE_className="form_label_container">
                    <View UNSAFE_className="form_label_text">Language</View>
                  </Flex>
                  <ComboBox width={'100%'}>
                    <Item key="red panda">Red Panda</Item>
                  </ComboBox>
                </Flex>
              </Flex>
            </Flex>
            <Flex UNSAFE_className="form_input_large">
              <Flex UNSAFE_className="form_label_container">
                <Flex UNSAFE_className="form_label">
                  <View UNSAFE_className="form_label_text">Description</View>
                </Flex>
              </Flex>
              <TextArea width={'100%'}></TextArea>
            </Flex>
          </Flex>

          <View UNSAFE_className={styles.footer}>
            <ContactButtons />
          </View>
        </View>
      </Flex>
    </Flex>
  );
};

export default ExternalNewContact;
