import { TRANSLATE } from "../../../utils/ServiceUtils";
import { ServiceBase } from "../ServiceBase";
import { IListCountryRequest } from "./reqeusts/ListCountryRequest";
import { IListLanguagesRequest } from "./reqeusts/ListLanguagesRequest";
import { ListCountryResponse } from "./responses/ListCountryResponse";
import { ListLanguagesResponse } from "./responses/ListLanguagesResponse";

export interface ITranslateService {
    listLanguages(request: IListLanguagesRequest): Promise<ListLanguagesResponse>;
    listCountry(request: IListCountryRequest): Promise<ListCountryResponse>;
}

export class TranslateService extends ServiceBase implements ITranslateService{
    private _addRootElementToLanguagesList(xml: string): string {
        xml = xml.replaceAll('</count><LANGUAGE>', '</count><LIST><LANGUAGE>');
        xml = xml.replaceAll('</LANGUAGE></ns1:ListLanguagesResponse>', '</LANGUAGE></LIST></ns1:ListLanguagesResponse>');
        return xml;
    }
    public async listLanguages(request: IListLanguagesRequest): Promise<ListLanguagesResponse> {
        let response: any = null;
        try {
            let tmp_response = (await this.makeCall(request.toXml(), TRANSLATE.listLanguages, request.cancelSource));
            response = this._addRootElementToLanguagesList(tmp_response.data);
            await this.updateCache(TRANSLATE.listLanguages, request.toXml(), response);
        } catch (error: any) {
            response = await this.getFromCache(TRANSLATE.listLanguages, request.toXml());
        }

        var parsedResponse = super.sendResponse(response).ListLanguagesResponse as ListLanguagesResponse;
        if(!super._checkErrors(parsedResponse)){

        };
        return parsedResponse;        
    }

    public async listCountry(request: IListCountryRequest): Promise<ListCountryResponse> {
        let response: any = null;
        try {
            let tmp_response = (await this.makeCall(request.toXml(), TRANSLATE.listCountry, request.cancelSource));
            response = tmp_response.data;
            await this.updateCache(TRANSLATE.listCountry, request.toXml(), response);
        } catch (error: any) {
            response = await this.getFromCache(TRANSLATE.listCountry, request.toXml());
        }

        var parsedResponse = super.sendResponse2(response, ['ROW']).ListCountryResponse as ListCountryResponse;
        if(!super._checkErrors(parsedResponse)){

        };
        return parsedResponse;        
    }
}