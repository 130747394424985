import React, { useEffect, useState } from 'react';
import { LayoutComponent } from '../LayoutComponent';
import { Cell, Column, Row, TableView, TableBody, TableHeader, Flex, Content, View, Link, ProgressCircle, useAsyncList } from '@adobe/react-spectrum';
import moment from 'moment';
import ExpenseLineAddEdit from './ExpenseLineAddEdit';
import { useDependency } from '../../../../contexts/DependencyProvider';
import { useViewInfo } from '../../../../hooks/UseViewInfo';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../state/store';
import { ToastQueue } from '@react-spectrum/toast';
import { ListTimesheetRequest } from '../../../../services/soap/scheduler/requests/ListTimesheetRequest';
import { GetContextPathRequest } from '../../../../services/soap/project/requests/GetContextPathRequest';
import { UpdateTimesheetRequest } from '../../../../services/soap/scheduler/requests/UpdateTimesheetRequest';
import { EvaluateTimesheetRequest } from '../../../../services/soap/scheduler/requests/EvaluateTimesheetRequest';
import { DeleteTimesheetRequest } from '../../../../services/soap/scheduler/requests/DeleteTimesheetRequest';
import { CreateTimesheetRequest } from '../../../../services/soap/scheduler/requests/CreateTimesheetRequest';
import { generateExpenseItem } from '../../../../services/soap/scheduler/requests/CreateTimesheetOptions';
import { GetTypeOfWorkForContextRequest } from '../../../../services/soap/scheduler/requests/GetTypeOfWorkForContextRequest';
import { GetSchedulerSettingsRequest, SettingsItemType } from '../../../../services/soap/scheduler/requests/GetSchedulerSettingsRequest';
import useComonentReload from '../../../../hooks/UseComponentReload';
import styles from './ExpenseLineComponent.module.css';

interface ExpenseLineProps {}

export interface RowData {
  ident: number;
  typeOfWorkId: string;
  typeOfWorkName: string;
  entryDateFormatted: string;
  entryDate: string;
  qty: string;
  description: string;
}
interface Character {
  typeOfWorkId: string;
  typeOfWorkName: string;
  entryDateFormatted: string;
  entryDate: string;
  qty: any;
  name: any;
}

function ExpenseLine(props: ExpenseLineProps) {
  const PAGE_SIZE: number = 50;
  let columnsData = [
    { name: 'Type of expense', key: 'typeOfWorkName' },
    { name: 'Date', key: 'entryDateFormatted' },
    { name: 'Quantity', key: 'qty' },
    { name: 'Description', key: 'description' },
  ];

  let columnsDataMobile = [
    { name: 'Type of expense', key: 'typeOfWorkName', width: 250 },
    { name: 'Date', key: 'entryDateFormatted', width: 150 },
    { name: 'Quantity', key: 'qty', width: 150 },
    { name: 'Description', key: 'description', width: 250 },
  ];

  let defaultRowData: RowData = {
    ident: 0,
    typeOfWorkId: '',
    typeOfWorkName: '',
    entryDateFormatted: '',
    entryDate: '',
    qty: '',
    description: '',
  };
  const [reloadComponent] = useComonentReload();
  const { schedulerService, projectService, store } = useDependency();
  const { isMobile } = useViewInfo();
  const selectedItem = useSelector((state: RootState) => state.finder.selectedItem);
  const [expenseLineData, setExpenseLineData] = useState<any>([]);
  const [totalTimeSheetRec, setTotalTimeSheetRec] = useState<number | undefined>(undefined);
  const [selectedRow, setSelectedRow] = useState<RowData>(defaultRowData);
  const [showLoader, setShowLoader] = useState<boolean>(true);
  const [showAddEditRow, setShowAddEditRow] = useState<boolean>(false);
  const [selectedKeys, setSelectedKeys] = useState<Set<any>>();
  const [folder_ID, setFolder_ID] = useState<string>('');
  const [typeofWorkList, setTypeofWorkList] = useState<any>([]);

  useEffect(() => {
    setShowAddEditRow(false);
    (async () => {
      await getTypeofWorkList();
      const contextRespone = await projectService.getContextPath(new GetContextPathRequest(store.Server, store.SessionId, Number(selectedItem?.id)));
      //console.log('contextRespone', contextRespone);
      const folder_ID = contextRespone.LIST[1]?.CONTEXT.parentId;
      setFolder_ID(String(folder_ID));
      setShowLoader(false);
    })();
  }, [selectedItem]);

  useEffect(() => {
    listExpenseData.reload();
  }, [reloadComponent, selectedItem]);

  const getTypeofWorkList = async () => {
    let scheduleResp = await schedulerService.getSchedulerSettings(new GetSchedulerSettingsRequest(store.Server, store.SessionId, '4', SettingsItemType.EXPENSE_ITEM_TYPE, 50, 0));
    console.log('scheduleResp', scheduleResp);

    let typeofWorkContextResp: any = await schedulerService.getTypeOfWorkForContext(new GetTypeOfWorkForContextRequest(store.Server, store.SessionId, true));
    console.log('typeofWorkContextResp', typeofWorkContextResp);

    const filteredTypeofWork = scheduleResp.ITEMS.filter(item => typeofWorkContextResp.SETTINGS.some((setting: any) => setting.SETTING.typeOfWork === item.ITEM.ident));
    //console.log('filteredTypeofWork', filteredTypeofWork);

    setTypeofWorkList(filteredTypeofWork);
  };

  const getExpenseLineCount = async () => {
    // Retrieve selected item
    //console.log('selectedItem', selectedItem);

    // Call API to fetch expense line data
    let expenseResp: any = await schedulerService.listTimeSheet(
      new ListTimesheetRequest(store.Server, store.SessionId, {
        etypeFilter: '2',
        statusFilter: '0',
        chargeFilter: '0',
        typeOfWorkFilter: '0',
        countOnly: '1',
        FOLDERS: {
          count: '1',
          FOLDER: {
            folderId: String(folder_ID),
            projectId: String(selectedItem?.parent_id),
            activityId: String(selectedItem?.id),
          },
        },
      })
    );
    //console.log('expenseResp.ITEMS[0].ITEM.totalRecords', expenseResp.ITEMS[0].ITEM.totalRecords);

    // If API call is successful
    if (expenseResp.result === 'OK') {
      setTotalTimeSheetRec(expenseResp.ITEMS[0].ITEM.totalRecords != 0 ? expenseResp.ITEMS[0].ITEM.totalRecords : undefined);
      return expenseResp.ITEMS[0].ITEM.totalRecords != 0 ? expenseResp.ITEMS[0].ITEM.totalRecords : undefined;
    }
  };

  const formatDecimalToString = (amount: any) => {
    // Check if the amount ends with '.00'
    if (amount.endsWith('.00')) {
      // If yes, remove the decimal part
      return amount.substring(0, amount.length - 3);
    }
    // If the decimal part is not '.00', return the original amount
    return amount;
  };

  let listExpenseData = useAsyncList<Character>({
    async load({ cursor }) {
      //console.log('cursor', cursor);

      let totalRecords = totalTimeSheetRec ?? (await getExpenseLineCount());
      //console.log('totalRecords', totalRecords);
      let currentPage = cursor ? parseInt(cursor) : 0;
      //console.log('currentPage', currentPage);
      let offset = PAGE_SIZE * currentPage;
      //console.log('offset', offset);
      if (isNaN(offset) || offset >= totalRecords) {
        setShowLoader(false);
        return { items: [], cursor: undefined };
      }

      let expenseResp = await schedulerService.listTimeSheet(
        new ListTimesheetRequest(store.Server, store.SessionId, {
          limit: PAGE_SIZE,
          offset,
          etypeFilter: '2',
          statusFilter: '0',
          chargeFilter: '0',
          typeOfWorkFilter: '0',
          FOLDERS: {
            count: '1',
            FOLDER: {
              folderId: String(folder_ID),
              projectId: String(selectedItem?.parent_id),
              activityId: String(selectedItem?.id),
            },
          },
        })
      );

      if (expenseResp.result === 'OK') {
        //console.log('expenseResp', expenseResp);
        setExpenseLineData(expenseResp.ITEMS);
        let expenseLineData = expenseResp.ITEMS.map(item => ({
          id: item.ITEM.ident,
          typeOfWorkId: item.ITEM.typeOfWorkId,
          typeOfWorkName: item.ITEM.typeOfWorkName,
          entryDateFormatted: moment(item.ITEM.entryDate).format('MM/DD/YYYY'),
          entryDate: moment(item.ITEM.entryDate).format('YYYY-MM-DD'),
          qty: formatDecimalToString(item.ITEM.qty),
          description: item.ITEM.description,
        }));

        return {
          items: expenseLineData,
          cursor: String(offset >= totalRecords ? undefined : currentPage + 1),
        };
      } else {
        setShowLoader(false);
        return { items: [], cursor: undefined };
      }
    },
  });

  const handleRowClick = (rowId: any): void => {
    //console.log('rowId', rowId.currentKey);
    //console.log('expenseLineData', expenseLineData);
    const rowData: any = expenseLineData.find((row: any) => row.ITEM.ident === rowId.currentKey);
    //console.log('rowData', rowData);
    setSelectedRow(rowData.ITEM);
    setShowAddEditRow(true);
    setSelectedKeys(rowId);
  };

  const handleRowClose = (): void => {
    setSelectedRow(defaultRowData);
    setShowAddEditRow(false);
    handleUnselect();
  };

  const handleUnselect = () => {
    setSelectedKeys(new Set());
  };

  const addRecord = async (record: any) => {
    try {
      setShowLoader(true);
      //console.log('addRecord', record);

      var result = await schedulerService.createTimeSheet(
        new CreateTimesheetRequest(
          store.Server,
          store.SessionId,
          generateExpenseItem(
            String(store.UserId),
            '',
            record.typeOfWorkId,
            '',
            record.description,
            record.qty,
            String(selectedItem?.id),
            '',
            String(selectedItem?.parent_id),
            '',
            folder_ID,
            '',
            new Date(record.entryDate)
          )
        )
      );

      //console.log('addRecord result', result);

      if (result.result === 'OK') {
        await schedulerService.evaluateTimeSheet(new EvaluateTimesheetRequest(store.Server, store.SessionId, { recordId: result.itemId }));
        setTotalTimeSheetRec(undefined);
        handleRowClose();
        listExpenseData.reload();
        ToastQueue.positive('Record saved successfully', { timeout: 3000 });
      } else {
        ToastQueue.negative(result.EXCEPTION.reason, { timeout: 3000 });
      }
    } catch (error: any) {
      ToastQueue.negative(error.message, { timeout: 3000 });
    } finally {
      setShowLoader(false);
    }
  };

  const updateRecord = async (record: any) => {
    try {
      setShowLoader(true);
      //console.log('expenseLineData', expenseLineData);
      //console.log('update record', record);
      let itemRow = expenseLineData.find((row: any) => row.ITEM.ident == record.ident);
      //console.log('itemRow', itemRow);
      let result = await schedulerService.updateTimeSheet(
        new UpdateTimesheetRequest(store.Server, store.SessionId, {
          ...itemRow.ITEM,
          description: record.description,
          entryDate: moment(record.entryDate).format('YYYY-MM-DDTHH:mm:ss'),
          qty: record.qty,
          typeOfWorkId: record.typeOfWorkId,
        })
      );

      // console.log('result', result);
      if (result.result === 'OK') {
        //console.log('result', result);
        await schedulerService.evaluateTimeSheet(new EvaluateTimesheetRequest(store.Server, store.SessionId, { recordId: result.itemId }));
        handleRowClose();
        listExpenseData.reload();
        ToastQueue.positive('Record updated successfully', { timeout: 3000 });
      } else {
        ToastQueue.negative(result.EXCEPTION.reason, { timeout: 3000 });
      }
    } catch (error) {
    } finally {
      setShowLoader(false);
    }
  };

  const deleteRecord = async (record: any) => {
    try {
      setShowLoader(true);
      //console.log('record', record);
      let result = await schedulerService.deleteTimeSheet(new DeleteTimesheetRequest(store.Server, store.SessionId, record.ident));
      //console.log('result', result);
      if (result.result === 'OK') {
        handleRowClose();
        listExpenseData.reload();
        ToastQueue.positive('Record deleted successfully', { timeout: 3000 });
      } else {
        ToastQueue.negative(result.EXCEPTION.reason, { timeout: 3000 });
      }
    } catch (error) {
    } finally {
      setShowLoader(false);
    }
  };

  if (showLoader) {
    return (
      <Flex width="100%" justifyContent={'center'} marginTop={10}>
        <ProgressCircle aria-label="Loading…" isIndeterminate />
      </Flex>
    );
  } else {
    return (
      <Flex direction={'column'} gap={'size-150'} position={'relative'} width={'100%'}>
        <Flex direction={'row'} alignItems={'start'} justifyContent={'start'}>
          <Content position={'relative'} UNSAFE_className={styles.heading_text}>
            Expense
          </Content>
          <Flex direction={'row'} alignItems={'center'} justifyContent={'center'} gap={'size-100'} UNSAFE_className={styles.icon_add_parent}>
            <Content>
              <Link
                isQuiet
                onPress={e => {
                  defaultRowData.entryDate = new Date().toISOString();
                  defaultRowData.qty = '1';
                  setSelectedRow(defaultRowData);
                  handleUnselect();
                  setShowAddEditRow(true);
                }}
              >
                <i className="bi bi-plus fs-5">
                  <View UNSAFE_className={styles.icon_add_text}>Add Expense</View>
                </i>
              </Link>
            </Content>
          </Flex>
        </Flex>
        <Flex direction={'column'}>
          <Flex maxHeight={{ base: '1000px', L: '450px' }} width="100%" direction="column" UNSAFE_style={{ overflowX: 'auto' }}>
            {listExpenseData.isLoading ? (
              <Flex width="100%" justifyContent={'center'} marginTop={10}>
                <ProgressCircle aria-label="Loading…" isIndeterminate />
              </Flex>
            ) : (
              <TableView
                aria-label="expense line"
                onSelectionChange={handleRowClick}
                selectionMode="single"
                selectedKeys={selectedKeys}
                selectionStyle="highlight"
                width={{ base: '100%', L: '100%', M: '100%' }}
                minHeight={'80px'}
                maxHeight={{ base: '1000px', L: '450px' }}
                marginBottom={'size-250'}
              >
                <TableHeader columns={isMobile ? columnsDataMobile : columnsData}>
                  {(column: any) => (
                    <Column showDivider width={column?.width}>
                      {column.name}
                    </Column>
                  )}
                </TableHeader>
                <TableBody items={listExpenseData.items} loadingState={listExpenseData.loadingState} onLoadMore={listExpenseData.loadMore}>
                  {item => <Row>{columnKey => <Cell>{(item as any)[columnKey] === 'Yes' ? <strong>{(item as any)[columnKey]}</strong> : (item as any)[columnKey]}</Cell>}</Row>}
                </TableBody>
              </TableView>
            )}
          </Flex>
          {showAddEditRow && (
            <ExpenseLineAddEdit
              id={String(selectedItem?.id)}
              defaultRowData={defaultRowData}
              typeofWorkList={typeofWorkList}
              selectedRowData={selectedRow}
              handleRowClose={handleRowClose}
              addRecord={addRecord}
              updateRecord={updateRecord}
              deleteRecord={deleteRecord}
              styles={styles}
            />
          )}
        </Flex>
      </Flex>
    );
  }
}

export const ExpenseLineComponent = LayoutComponent(ExpenseLine);
