import { BaseRequest, IBaseRequest } from '../../BaseRequest';

export interface INewActivityUserRequest extends IBaseRequest {
    roleName?:      string;
    role?:          string;
    note?:          string;
    id?:            string;
    userId:         number;
    activityName?:  string;
    acl?:           string;
    activity:       number;
    isPrimary:      number;
    contact:        number;
    userName?:      string;
}

export class NewActivityUserRequest extends BaseRequest implements INewActivityUserRequest {

    public roleName?:       string;
    public role?:           string;
    public note?:           string;
    public id?:             string;
    public userId:          number;
    public activityName?:   string;
    public acl?:            string;
    public activity:        number;
    public isPrimary:       number;
    public contact:         number;
    public userName?:       string;

    constructor(server: string, session: string, 
        userId: number, activity: number, 
        isPrimary: number, contact:number, 
        otherOptions: any
    ) {
        super(server, session);

        this.roleName = otherOptions.roleName;
        this.role = otherOptions.role;
        this.note = otherOptions.note;
        this.id = otherOptions.id;
        this.userId = userId;
        this.activityName = otherOptions.activityName;
        this.acl = otherOptions.acl;
        this.activity = activity;
        this.isPrimary = isPrimary;
        this.contact = contact;
        this.userName = otherOptions.userName;
    }

    public toXml(): string {
        var parameters: string = super.toXml();

        if (this.roleName) parameters += `<roleName>${this.roleName}</roleName>`;
        if (this.role) parameters += `<role>${this.role}</role>`;
        if (this.note) parameters += `<note>${this.note}</note>`;
        if (this.id) parameters += `<id>${this.id}</id>`;
        parameters += `<userId>${this.userId}</userId>`;
        if (this.activityName) parameters += `<activityName>${this.activityName}</activityName>`;
        if (this.acl) parameters += `<acl>${this.acl}</acl>`;
        parameters += `<activity>${this.activity}</activity>`;
        parameters += `<isPrimary>${this.isPrimary}</isPrimary>`;
        parameters += `<contact>${this.contact}</contact>`;
        if (this.userName) parameters += `<userName>${this.userName}</userName>`;
        
        return super.wrapperXml('NewActivityUser', parameters);
    }
}
