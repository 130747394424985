import { LayoutComponent } from '../LayoutComponent';
import Refresh from '@spectrum-icons/workflow/Refresh';
import styles from './RefreshButtonComponent.module.css';

export interface IRefreshButtonProps {
    percentWidth?: number;
}

function RefreshButton ({ percentWidth }: IRefreshButtonProps) {
    const handleRefresh = () => {
        // console.log('Refresh');
        window.location.reload();
    }
    return (
        <>
            <button className={styles.refreshButton} style={{ width: percentWidth ? `${percentWidth}%` : '100%' }} onClick={handleRefresh}>
                <Refresh size={'S'} />
            </button>
        </>
    );
}

export const RefreshButtonComponent = LayoutComponent(RefreshButton);