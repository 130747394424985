import axios from "axios";
import { BrowserStorage } from "../LocalStorage/BrowserStorage";
import { IStore } from "../LocalStorage/IStore";

export interface IBaseRequest {
    server: string;
    session: string;
    cancelSource: any;
    toXml(): string;
}
export class BaseRequest implements IBaseRequest {
    private _store:IStore;
    public server: string;
    public session: string;
    public cancelSource: any;

    constructor(_server: string, _session: string) {
        this._store = new BrowserStorage();
        this.server = _server;
        this.session = _session;
        this.cancelSource = axios.CancelToken.source();
    }
    protected get isRemembered():boolean{
        return this._store.Username !== '' && this._store.Password !== '';
    }
    protected wrapperXml(method:string, xml:string):string{
        return `<soapenv:Envelope xmlns:soapenv="http://schemas.xmlsoap.org/soap/envelope/" xmlns:wsdl="http://atollon.com/enterprise/wsdl">
        <soapenv:Header/>
        <soapenv:Body>
           <wsdl:${method}>
              ${xml}
           </wsdl:${method}>
        </soapenv:Body>
     </soapenv:Envelope>`;
    }

    toXml() {
        return `<server>${this.server}</server>
        <session>${this.session}</session>`;
    }
}