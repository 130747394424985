import { BaseRequest, IBaseRequest } from '../../BaseRequest';

export interface IGetFolderProjectTypeRequest extends IBaseRequest {
    id?: number;
    systemId?: string;
}

export class GetFolderProjectTypeRequest extends BaseRequest implements IGetFolderProjectTypeRequest {
    public id?: number;
    public systemId?: string;

    constructor(server: string, session: string, id?: number, systemId?: string) {
        super(server, session);
        this.id = id;
        this.systemId = systemId;
    }

    public toXml(): string {
        var parameters: string = super.toXml();

        if (this.id) parameters += '<id>' + this.id + '</id>';
        if (this.systemId) parameters += '<systemId>' + this.systemId + '</systemId>';
        
        return super.wrapperXml('GetFolderProjectType', parameters);
    }
}
