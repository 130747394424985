
export enum ImageVariant {
    /** 640x500 */
    PICTURE_LARGE = 'large',
    /** 300x300 */
    PICTURE_MEDIUM = 'medium',
    /** 150x150 */
    PICTURE_SMALL = 'small',
    /** 48x48 */
    PICTURE_CONTACT_FINDER = 'contact-finder',
    /** 75x100 */
    PICTURE_CONTACT_THUMBNAIL = 'contact-thumbnail',
}

const URL_MOORING  = '/mooring/documentThumbnail/';

export const getImagePath = (docId: string, variant: ImageVariant = ImageVariant.PICTURE_LARGE, base_url:string, server:string, session:string): string => {
    if(!docId) return '';
    return `${base_url}${URL_MOORING}${variant}?server=${server}&session=${session}&id=${docId}`;
};