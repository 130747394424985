import { SoapUtils } from "../../utils/SoapUtils";
import { ISoapClient, SoapClient } from "./SoapClient";

import { apiDataDB } from "../../data/db";
import { BaseResponse } from "./BaseResponse";

export class ServiceBase {
    public static SessionExpiredHandler:()=>void = ()=>{};

    soapClient:ISoapClient;
    SOAP_URL: string;

    public _checkErrors(response:BaseResponse):boolean{
        const SESSION_EXPIRED = '65540';

        if(response && response.EXCEPTION){
            switch (response.EXCEPTION.code) {
                case SESSION_EXPIRED:
                    ServiceBase.SessionExpiredHandler();
                    throw new Error('SESSION_EXPIRED');
                default:
                    // throw new Error(response.EXCEPTION.message);
                    break;
            }
            return false;
        }
        return true;
    }

    constructor(_url:string){
        this.soapClient = new SoapClient();
        this.SOAP_URL = _url;
    }

    sendResponse(response:string):any{
        try{
            return SoapUtils.parseXmlString(response).Envelope.Body
        }catch(e){
            return { EXCEPTION: { code: '0', message: 'Invalid response' } };
        }
    }
    
    sendResponse2(response:string, collectionName:string[] = []):any{
        try{
            return SoapUtils.parseXmlString2(response, collectionName).Envelope.Body
        }catch(e){
            return { EXCEPTION: { code: '0', message: 'Invalid response' } };
        }
    }
    
    makeCall(requestXml:string, action:string, cancelSource:any):Promise<any>{
        return this.soapClient.makeSoapCall(requestXml, this.SOAP_URL, action, cancelSource);
    }

    async updateCache(type: string,  request: string, response: string) {
        var cache_data = {type, url: this.SOAP_URL, request , response};
        await apiDataDB.apiData.add(cache_data);
    }

    async getFromCache(type: string,  request: string) {
        let apiData = await apiDataDB.apiData.where({type, url: this.SOAP_URL, request}).last();
        return apiData?.response? apiData.response : '';
    }
}