import { TEAM } from "../../../utils/ServiceUtils";
import { ServiceBase } from "../ServiceBase";
import { IListActivityUserRequest } from "./requests/ListActivityUserRequest";
import { INewActivityUserRequest } from "./requests/NewActivityUserRequest";
import { ListActivityUserResponse } from "./responses/ListActivityUserResponse";
import { NewActivityUserResponse } from "./responses/NewActivityUserResponse";

export interface ITeamService {
    listActivityUser(request:IListActivityUserRequest): Promise<ListActivityUserResponse>;
    newActivityUser(request: INewActivityUserRequest): Promise<NewActivityUserResponse>;
}

export class TeamService extends ServiceBase implements ITeamService{
    public async listActivityUser(request: IListActivityUserRequest): Promise<ListActivityUserResponse> {
        let response: any = null;
        try {
            let tmp_response = (await this.makeCall(request.toXml(), TEAM.listActivityUser, request.cancelSource));
            response = tmp_response.data;
            await this.updateCache(TEAM.listActivityUser, request.toXml(), response);
        } catch (error: any) {
            response = await this.getFromCache(TEAM.listActivityUser, request.toXml());
        }

        var parsedResponse = super.sendResponse2(response, ['ROW']).ListActivityUserResponse as ListActivityUserResponse;
        if(!super._checkErrors(parsedResponse)){

        };
        return parsedResponse;        
    }

    public async newActivityUser(request: INewActivityUserRequest): Promise<NewActivityUserResponse> {
        let response: any = null;
        try {
            let tmp_response = (await this.makeCall(request.toXml(), TEAM.newActivityUser, request.cancelSource));
            response = tmp_response.data;
            await this.updateCache(TEAM.newActivityUser, request.toXml(), response);
        } catch (error: any) {
            response = await this.getFromCache(TEAM.newActivityUser, request.toXml());
        }

        var parsedResponse = super.sendResponse2(response).NewActivityUserResponse as NewActivityUserResponse;
        if(!super._checkErrors(parsedResponse)){

        };
        return parsedResponse;        
    }
}