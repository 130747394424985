import { DOCUMENT_LIBRARY } from '../../../utils/ServiceUtils';
import {ServiceBase} from '../ServiceBase';
import { ICreateDocumentInStorageRequest } from './requests/CreateDocumentInStorageRequest';
import { IListDocumentCategoryRequest } from './requests/ListDocumentCategoryRequest';
import { IListDocumentLocationRequest } from './requests/ListDocumentLocationRequest';
import { IListDocumentRequest } from './requests/ListDocumentRequest';
import { IListDocumentTypeRequest } from './requests/ListDocumentTypeRequest';
import { IPermanentlyDeleteDocumentRequest } from './requests/PermanentlyDeleteDocumentRequest';
import { CreateDocumentInStorageResponse } from './responses/CreateDocumentInStorageResponse';
import { ListDocumentCategoryResponse } from './responses/ListDocumentCategoryResponse';
import { ListDocumentLocationResponse } from './responses/ListDocumentLocationResponse';
import { ListDocumentResponse } from './responses/ListDocumentResponse';
import { ListDocumentTypeResponse } from './responses/ListDocumentTypeResponse';
import { PermanentlyDeleteDocumentResponse } from './responses/PermanentlyDeleteDocumentResponse';

export interface IDocumentLibraryService {
    listDocument(request:IListDocumentRequest):Promise<ListDocumentResponse>;
    listDocumentType(request:IListDocumentTypeRequest):Promise<ListDocumentTypeResponse>;
    listDocumentCategory(request:IListDocumentCategoryRequest):Promise<ListDocumentCategoryResponse>;
    listDocumentLocation(request:IListDocumentLocationRequest):Promise<ListDocumentLocationResponse>;
    permanentlyDeleteDocument(request:IPermanentlyDeleteDocumentRequest):Promise<PermanentlyDeleteDocumentResponse>;
    createDocumentInStorage(request:ICreateDocumentInStorageRequest):Promise<CreateDocumentInStorageResponse>;
}

export class DocumentLibraryService extends ServiceBase implements IDocumentLibraryService { 
    
    public async listDocument(request: IListDocumentRequest): Promise<ListDocumentResponse>{
        let response:any = null;
        try {
            let tmp_response = (await this.makeCall(request.toXml(), DOCUMENT_LIBRARY.listDocument, request.cancelSource));
            response = (tmp_response.data);
            await this.updateCache(DOCUMENT_LIBRARY.listDocument, request.toXml(), response);
        } catch (error:any) {
            response = await this.getFromCache(DOCUMENT_LIBRARY.listDocument, request.toXml());
        }

        var parsedResponse = super.sendResponse2(response, ['DOCUMENT']).ListDocumentResponse as ListDocumentResponse;
        if(!super._checkErrors(parsedResponse)){

        };
        return parsedResponse;
    }

    public async listDocumentType(request: IListDocumentTypeRequest): Promise<ListDocumentTypeResponse>{
        let response:any = null;
        try {
            response = (await this.makeCall(request.toXml(), DOCUMENT_LIBRARY.listDocumentType, request.cancelSource)).data ;
            await this.updateCache(DOCUMENT_LIBRARY.listDocumentType, request.toXml(), response);
        } catch (error:any) {
            response = await this.getFromCache(DOCUMENT_LIBRARY.listDocumentType, request.toXml());
        }

        var parsedResponse = super.sendResponse2(response, ['item']).ListDocumentTypeResponse as ListDocumentTypeResponse;
        if(!super._checkErrors(parsedResponse)){

        };
        return parsedResponse;
    }

    public async listDocumentCategory(request: IListDocumentCategoryRequest): Promise<ListDocumentCategoryResponse>{
        let response:any = null;
        try {
            response = (await this.makeCall(request.toXml(), DOCUMENT_LIBRARY.listDocumentCategory, request.cancelSource)).data ;
            await this.updateCache(DOCUMENT_LIBRARY.listDocumentCategory, request.toXml(), response);
        } catch (error:any) {
            response = await this.getFromCache(DOCUMENT_LIBRARY.listDocumentCategory, request.toXml());
        }

        var parsedResponse = super.sendResponse2(response, ['item']).ListDocumentCategoryResponse as ListDocumentCategoryResponse;
        if(!super._checkErrors(parsedResponse)){

        };
        return parsedResponse;
    }

    public async listDocumentLocation(request: IListDocumentLocationRequest): Promise<ListDocumentLocationResponse>{
        let response:any = null;
        try {
            response = (await this.makeCall(request.toXml(), DOCUMENT_LIBRARY.listDocumentLocation, request.cancelSource)).data ;
            await this.updateCache(DOCUMENT_LIBRARY.listDocumentLocation, request.toXml(), response);
        } catch (error:any) {
            response = await this.getFromCache(DOCUMENT_LIBRARY.listDocumentLocation, request.toXml());
        }

        var parsedResponse = super.sendResponse2(response, ['item']).ListDocumentLocationResponse as ListDocumentLocationResponse;
        if(!super._checkErrors(parsedResponse)){

        };
        return parsedResponse;
    }
    
    public async permanentlyDeleteDocument(request: IPermanentlyDeleteDocumentRequest): Promise<PermanentlyDeleteDocumentResponse>{
        let response:any = null;
        try {
            response = (await this.makeCall(request.toXml(), DOCUMENT_LIBRARY.permanentlyDeleteDocument, request.cancelSource)).data ;
            await this.updateCache(DOCUMENT_LIBRARY.permanentlyDeleteDocument, request.toXml(), response);
        } catch (error:any) {
            response = await this.getFromCache(DOCUMENT_LIBRARY.permanentlyDeleteDocument, request.toXml());
        }

        var parsedResponse = super.sendResponse(response).PermanentlyDeleteDocumentResponse as PermanentlyDeleteDocumentResponse;
        if(!super._checkErrors(parsedResponse)){

        };
        return parsedResponse;
    }

    public async createDocumentInStorage(request: ICreateDocumentInStorageRequest): Promise<CreateDocumentInStorageResponse>{
        let response:any = null;
        try {
            response = (await this.makeCall(request.toXml(), DOCUMENT_LIBRARY.createDocumentInStorage, request.cancelSource)).data ;
            await this.updateCache(DOCUMENT_LIBRARY.createDocumentInStorage, request.toXml(), response);
        } catch (error:any) {
            response = await this.getFromCache(DOCUMENT_LIBRARY.createDocumentInStorage, request.toXml());
        }

        var parsedResponse = super.sendResponse(response).CreateDocumentInStorageResponse as CreateDocumentInStorageResponse;
        if(!super._checkErrors(parsedResponse)){

        };
        return parsedResponse;
    }
}