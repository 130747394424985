import { Flex, View } from '@adobe/react-spectrum';
import styles from './Dashboard.module.css';
import jsonPackage from '../../../package.json';
import { useDependency } from '../../contexts/DependencyProvider';
import { ListContactListRequest } from '../../services/soap/main/requests/ListContactListRequest';
import { GetFromContactListRequest } from '../../services/soap/main/requests/GetFromContactListRequest';
import { ListCountryRequest } from '../../services/soap/translate/reqeusts/ListCountryRequest';
import { ListActivityUserRequest } from '../../services/soap/team/requests/ListActivityUserRequest';
import { NewActivityUserRequest } from '../../services/soap/team/requests/NewActivityUserRequest';

const Dashboard = () => {
    const { 
      mainService, 
      featureService, 
      translateService, 
      projectService, 
      formService,
      itemService, 
      financeService,
      globalService,
      schedulerService,
      accessService,
      printRestService,
      documentLibraryService,
      teamService,
      store 
    } = useDependency();
    const today = new Date();
    const handleClick = async () => {
      try {
        // var contactList = await mainService.listContactList(new ListContactListRequest(store.Server, store.SessionId, {
        //   useDefaultTreeHandle: true,
        //   list: 'contacttype'
        // }));

        // console.log('contactList', contactList);

        // var fromContactList = await mainService.getFromContactList(new GetFromContactListRequest(store.Server, store.SessionId, {
        //   id: '132026101',
        //   list: 'contacttype'
        // }));

        // console.log('fromContactList', fromContactList);

        // var country = await translateService.listCountry(new ListCountryRequest(store.Server, store.SessionId, {}));

        // console.log('country', country);

        // var listActivityUser = await teamService.listActivityUser(new ListActivityUserRequest(store.Server, store.SessionId, 524755101));

        // console.log('listActivityUser: ', listActivityUser);

        var newActivityUser = await teamService.newActivityUser(new NewActivityUserRequest(store.Server, store.SessionId, 449269101, 524755101, 0, 449285101, { note: 'this is add from API' }));

        console.log('newActivityUser: ', newActivityUser);
      } catch (error) {
        console.log('error', error);
      }
    };
    return ( <>
        
        <div className={styles.centerText} onClick={handleClick}>
            Welcome to Atollon
        </div>
        <Flex direction="column" alignItems="center">
              <View>
                <p style={{ paddingTop: '10px' }}>
                  v{jsonPackage.version} Copyright by Atollon {today.getFullYear()}
                </p>
              </View>
        </Flex>
    </> );
}
 
export default Dashboard;