import { SoapUtils } from '../../../../utils/SoapUtils';
import { BaseRequest, IBaseRequest } from '../../BaseRequest';

export interface Document {
    id?:             string;
    acl?:            string;
    parent?:         string;
    name?:           string;
    description?:    string;
    type?:           string;
    location?:       string;
    category?:       string;
    validByDate?:    string;
    createdBy?:      string;
    fileHandle?:     string;
    treeHandle?:     string;
    fileSize?:       string;
    internalNumber?: string;
    fileName?:       string;
    deleted?:        string;
    creationDate?:   Date;
    lastChangeDate?: Date;
    validFrom?:      Date;
    validTo?:        Date;
    deleteDate?:     Date;
    storageId?:      string;
    isInStorage?:    string;
    contextId?:      string;
}

function toDocumentXml(document: Document):string{
    var xml = '<DOCUMENT>';
    if(document.id) xml += `<id>${document.id}</id>`;
    if(document.acl) xml += `<acl>${document.acl}</acl>`;
    if(document.parent) xml += `<parent>${document.parent}</parent>`;
    if(document.name) xml += `<name>${document.name}</name>`;
    if(document.description) xml += `<description>${document.description}</description>`;
    if(document.type) xml += `<type>${document.type}</type>`;
    if(document.location) xml += `<location>${document.location}</location>`;
    if(document.category) xml += `<category>${document.category}</category>`;
    if(document.validByDate) xml += `<validByDate>${document.validByDate}</validByDate>`;
    if(document.createdBy) xml += `<createdBy>${document.createdBy}</createdBy>`;
    if(document.fileHandle) xml += `<fileHandle>${document.fileHandle}</fileHandle>`;
    if(document.treeHandle) xml += `<treeHandle>${document.treeHandle}</treeHandle>`;
    if(document.fileSize) xml += `<fileSize>${document.fileSize}</fileSize>`;
    if(document.internalNumber) xml += `<internalNumber>${document.internalNumber}</internalNumber>`;
    if(document.fileName) xml += `<fileName>${document.fileName}</fileName>`;
    if(document.deleted) xml += `<deleted>${document.deleted}</deleted>`;
    if(document.creationDate) xml += `<creationDate>${SoapUtils.toDateForXml(document.creationDate)}</creationDate>`;
    if(document.lastChangeDate) xml += `<lastChangeDate>${SoapUtils.toDateForXml(document.lastChangeDate)}</lastChangeDate>`;
    if(document.validFrom) xml += `<validFrom>${SoapUtils.toDateForXml(document.validFrom)}</validFrom>`;
    if(document.validTo) xml += `<validTo>${SoapUtils.toDateForXml(document.validTo)}</validTo>`;
    if(document.deleteDate) xml += `<deleteDate>${SoapUtils.toDateForXml(document.deleteDate)}</deleteDate>`;
    if(document.storageId) xml += `<storageId>${document.storageId}</storageId>`;
    if(document.isInStorage) xml += `<isInStorage>${document.isInStorage}</isInStorage>`;
    if(document.contextId) xml += `<contextId>${document.contextId}</contextId>`;
    xml += '</DOCUMENT>';
    return xml;
}
export interface ICreateDocumentInStorageRequest extends IBaseRequest {
    DOCUMENT:      Document;
    data:          string;
}

export class CreateDocumentInStorageRequest extends BaseRequest implements ICreateDocumentInStorageRequest {
    public DOCUMENT: Document;
    public data:     string;
    constructor(server: string, session: string, document: Document, data: string) {
        super(server, session);
        this.DOCUMENT = document;
        this.data = data;
    }

    public toXml(): string {
        var parameters: string = super.toXml();
        parameters += toDocumentXml(this.DOCUMENT);
        parameters += `<data>${this.data}</data>`;
        return super.wrapperXml('CreateDocumentInStorage', parameters);
    }
}
