import React from 'react';
import { Flex, Link, TextField, View } from '@adobe/react-spectrum';
import { useDependency } from '../../../../../contexts/DependencyProvider';
import { t } from 'i18next';
import { useViewInfo } from '../../../../../hooks/UseViewInfo';
import styles from './ExternalSearchContacts.module.css';

interface ExternalContactsSearchProps {
  openDialog: (isOpen: boolean) => void;
  closeDialog: (isOpen: boolean) => void;
}

const ExternalContactsSearch: React.FC<ExternalContactsSearchProps> = ({ openDialog, closeDialog }) => {
  const { isMobile } = useViewInfo();
  const { itemService, globalService, financeService, store } = useDependency();

  const NewContactButton = () => (
    <Flex UNSAFE_className={styles.button_container_contact_search_second}>
      <i className={`${styles.icon_add} bi bi-plus fs-5`} />
      <Link isQuiet UNSAFE_className={styles.new_contact_text} onPress={() => openDialog(true)}>
        {isMobile ? 'New' : 'New Contact'}
      </Link>
    </Flex>
  );

  const ContactActions = () => (
    <Flex UNSAFE_className={styles.button_container_contact_search}>
      <Flex UNSAFE_className={styles.contact_search_cancel}>
        <Link isQuiet UNSAFE_className={styles.contact_search_cancel_text} onPress={() => closeDialog(false)}>
          Cancel
        </Link>
      </Flex>
      <Flex UNSAFE_className={styles.contact_search_add}>
        <Link isQuiet UNSAFE_className={styles.contact_search_add_text}>
          Add Contact
        </Link>
      </Flex>
    </Flex>
  );

  const ContactButtons = () => (
    <Flex direction={isMobile ? 'column' : { base: 'column', L: 'row' }} justifyContent={'end'} gap={'size-100'}>
      <NewContactButton />
      <ContactActions />
    </Flex>
  );

  return (
    <Flex direction="column" width={'100%'} justifyContent={'center'} alignContent={'center'} UNSAFE_style={{ overflowY: 'auto' }}>
      <Flex direction="column" width={'100%'} justifyContent={'center'} alignContent={'center'}>
        <View UNSAFE_className={styles.add_contact_parent}>
          <View UNSAFE_className={styles.add_contact_child}></View>
          {/* <View UNSAFE_className={styles.add_client_contact_heading}>Add Client Contact</View> */}
          <View UNSAFE_className={styles.contact_list_heading}>Contact list</View>
          <Flex UNSAFE_className={styles.contact_search_parent}>
            <Flex UNSAFE_className={styles.contact_search_child}>
              <TextField UNSAFE_className={styles.contact_search_field} width={'100%'} />
              <i className={`${styles.icon_contact_search} bi bi-search`}></i>
            </Flex>
          </Flex>
          <Flex UNSAFE_className={styles.tabs_container}>
            <Flex UNSAFE_className={styles.tab_chlid}>
              <View UNSAFE_className={styles.tab_label}>All</View>
            </Flex>
            <Flex UNSAFE_className={styles.tab_chlid_second}>
              <View UNSAFE_className={styles.tab_label}>
                <span>Company</span>
                <span className={styles.pipe_sign_color}> |</span>
                <span> Main </span>
              </View>
            </Flex>
            <Flex UNSAFE_className={styles.tab_chlid_second}>
              <View UNSAFE_className={styles.tab_label}>
                <span>Company </span>
                <span className={styles.pipe_sign_color}>|</span>
                <span> Others</span>
              </View>
            </Flex>
            <Flex UNSAFE_className={styles.tab_chlid_second}>
              <View UNSAFE_className={styles.tab_label}>Project</View>
            </Flex>
          </Flex>
          <Flex UNSAFE_className={styles.contacts_search_result}>
            <div className={styles.select_cell_parent}>
              <div className={styles.select_cell}>
                <div className={styles.text_content_container}>
                  <div className={styles.label4}>Petr Hošek</div>
                </div>
              </div>
              <div className={styles.select_cell1}>
                <i className={`${styles.icon_search} bi bi-envelope-fill`}></i>
                <div className={styles.text_content_container}>
                  <div className={styles.label4}>petr000@gmail.com</div>
                </div>
              </div>
              <div className={styles.select_cell2}>
                <i className={`${styles.icon_search} bi bi-telephone-fill`}></i>
                <div className={styles.text_content_container}>
                  <div className={styles.label4}>222 920 192</div>
                </div>
              </div>
            </div>
          </Flex>
          <View UNSAFE_className={styles.footer}>
            <ContactButtons />
          </View>
        </View>
      </Flex>
    </Flex>
  );
};

export default ExternalContactsSearch;
