import { BaseRequest, IBaseRequest } from '../../BaseRequest';
import { Folder, toFolderXml } from '../../dataObjects/Folder';

export interface IUpdateFolderRequest extends IBaseRequest {
    folder: Folder;
}

export class UpdateFolderRequest extends BaseRequest implements IUpdateFolderRequest {
  public folder: Folder;
  constructor(server: string, session: string, folder: Folder) {
    super(server, session);
    this.folder = folder;
  }

  public toXml(): string {
    var parameters: string = super.toXml();
    parameters += toFolderXml(this.folder);
    return super.wrapperXml('UpdateFolder', parameters);
  }
}
