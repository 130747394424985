import { TaskItem } from '../../dataObjects/TaskItem';

export function generateTaskItem(
  activityId: string,
  projectId: string,
  folderId: string,
  userId: string,
  title: string,
  typeOfWorkId: string,
  solverId: string,
  solutionStatusId: string,
  u_item: TaskItem
): TaskItem {
  var item = {
    ...u_item,
    activityId: activityId,
    projectId: projectId,
    folderId: folderId,
    // solutionStatus: '131816081',
    solutionStatus: solutionStatusId,
    userId: solverId,
    title: title,
    entryType: typeOfWorkId,
    forUser: userId,
    dateFrom: new Date().toISOString(),
    dateTo: new Date().toISOString(),
    USER: { id: solverId },
    headUserId: solverId,
    GARANT: { userId: userId === solverId ? '' : userId },
  };
  return item;
}
