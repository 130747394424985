import React, { useEffect, useRef, useState } from 'react';
import { LayoutComponent } from '../LayoutComponent';
import { Flex, Content, View, Link, ProgressCircle, DialogContainer, Dialog, TextArea, Header } from '@adobe/react-spectrum';
import ExternalContactsSearch from './component/ExternalContactsSearch';
import { useDependency } from '../../../../contexts/DependencyProvider';
import { useViewInfo } from '../../../../hooks/UseViewInfo';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../state/store';
import { ToastQueue } from '@react-spectrum/toast';
import useComonentReload from '../../../../hooks/UseComponentReload';
import ExternalNewContact from './component/ExternalNewContact';
import { GetActivityRequest } from '../../../../services/soap/project/requests/GetActivityRequest';
import { FPADataTypes } from '../../../../infra/protected/FPA/FPAData';
import { UpdateActivityRequest } from '../../../../services/soap/project/requests/UpdateActivityRequest';
import ContactCard from './component/ContactCard';
import styles from './ExternalContacts.module.css';
import { GetProjectRequest } from '../../../../services/soap/project/requests/GetProjectRequest';
import { UpdateProjectRequest } from '../../../../services/soap/project/requests/UpdateProjectRequest';
import { GetFolderRequest } from '../../../../services/soap/project/requests/GetFolderRequest';
import { UpdateFolderRequest } from '../../../../services/soap/project/requests/UpdateFolderRequest';

interface ExternalContactsProps {}
type UpdateRequestFunction = () => Promise<{ result: string }>;
function ExternalContacts() {
  const [reloadComponent] = useComonentReload();
  const { projectService, store } = useDependency();
  const { isMobile } = useViewInfo();
  const selectedItem = useSelector((state: RootState) => state.finder.selectedItem);
  const [externalContacts, setExternalContacts] = useState<any>([]);
  const [showLoader, setShowLoader] = useState<boolean>(true);
  const [selectedKeys, setSelectedKeys] = useState<Set<any>>();
  const [isOpenSearchContact, setIsOpenSearchContact] = useState<boolean>(false);
  const [isOpenNewContact, setIsOpenNewContact] = useState<boolean>(false);
  const current_Item = useRef<any>(null);
  useEffect(() => {
    (async () => {
      setShowLoader(true);
      await loadExternalContacts();
      setShowLoader(false);
    })();
  }, [selectedItem, reloadComponent]);

  const loadExternalContacts = async () => {
    if (!selectedItem) return;

    const setCurrentItemAndContacts = (item: any) => {
      current_Item.current = item;
      setExternalContacts(item?.EXTERNAL_CONTACTS?.ROWS);
    };

    switch (selectedItem.type) {
      case FPADataTypes.ACTIVITY:
        const { ACTIVITY } = await projectService.getActivity(new GetActivityRequest(store.Server, store.SessionId, Number(selectedItem.id)));
        setCurrentItemAndContacts(ACTIVITY);
        break;
      case FPADataTypes.PROJECT:
        const { PROJECT } = await projectService.getProject(new GetProjectRequest(store.Server, store.SessionId, selectedItem.id));
        setCurrentItemAndContacts(PROJECT);
        break;
      case FPADataTypes.FOLDER:
        const { FOLDER } = await projectService.getFolder(new GetFolderRequest(store.Server, store.SessionId, selectedItem.id));
        setCurrentItemAndContacts(FOLDER);
        break;
    }
  };

  const updateItem = async (updateRequest: UpdateRequestFunction, successMessage: string) => {
    const updatedItem = await updateRequest();
    if (updatedItem.result === 'OK') {
      ToastQueue.positive(successMessage, { timeout: 3000 });
    }
  };

  const updateContactNote = (contactId: string, description: string) => {
    current_Item.current.EXTERNAL_CONTACTS?.ROWS?.forEach((contact: any) => {
      if (contact.EXTERNAL_CONTACT.id === contactId) {
        contact.EXTERNAL_CONTACT.note = description;
      }
    });
  };

  const onSaveDescription = async (contactId: string, description: string) => {
    if (!selectedItem) return;

    updateContactNote(contactId, description);

    const requestData = {
      server: store.Server,
      sessionId: store.SessionId,
      data: {
        ...current_Item.current,
        EXTERNAL_CONTACTS: current_Item.current.EXTERNAL_CONTACTS,
      },
    };

    switch (selectedItem.type) {
      case FPADataTypes.ACTIVITY:
        await updateItem(() => projectService.updateActivity(new UpdateActivityRequest(requestData.server, requestData.sessionId, requestData.data)), 'Description saved successfully');
        break;
      case FPADataTypes.PROJECT:
        await updateItem(() => projectService.updateProject(new UpdateProjectRequest(requestData.server, requestData.sessionId, requestData.data)), 'Description saved successfully');
        break;
      case FPADataTypes.FOLDER:
        await updateItem(() => projectService.updateFolder(new UpdateFolderRequest(requestData.server, requestData.sessionId, requestData.data)), 'Description saved successfully');
        break;
    }
  };

  const updateContactRating = (contactId: string, rating: string) => {
    current_Item.current.EXTERNAL_CONTACTS?.ROWS?.forEach((contact: any) => {
      if (contact.EXTERNAL_CONTACT.id === contactId) {
        contact.EXTERNAL_CONTACT.relationshipLevel = rating;
      }
    });
  };

  const onSaveRating = async (contactId: string, rating: string) => {
    if (!selectedItem) return;

    updateContactRating(contactId, rating);

    const requestData = {
      server: store.Server,
      sessionId: store.SessionId,
      data: {
        ...current_Item.current,
        EXTERNAL_CONTACTS: current_Item.current.EXTERNAL_CONTACTS,
      },
    };

    switch (selectedItem.type) {
      case FPADataTypes.ACTIVITY:
        await updateItem(() => projectService.updateActivity(new UpdateActivityRequest(requestData.server, requestData.sessionId, requestData.data)), 'Rating saved successfully');
        break;
      case FPADataTypes.PROJECT:
        await updateItem(() => projectService.updateProject(new UpdateProjectRequest(requestData.server, requestData.sessionId, requestData.data)), 'Rating saved successfully');
        break;
      case FPADataTypes.FOLDER:
        await updateItem(() => projectService.updateFolder(new UpdateFolderRequest(requestData.server, requestData.sessionId, requestData.data)), 'Rating saved successfully');
        break;
    }
  };

  const handleUnselect = () => {
    setSelectedKeys(new Set());
  };

  const addRecord = async (record: any) => {
    try {
      setShowLoader(true);
    } catch (error: any) {
      ToastQueue.negative(`Add record error : ${error.message}`, { timeout: 3000 });
    } finally {
      setShowLoader(false);
    }
  };

  const updateRecord = async (record: any) => {
    try {
      //console.log('Update record', record);
      setShowLoader(true);
    } catch (error: any) {
      ToastQueue.negative(`Update record error : ${error.message}`, { timeout: 3000 });
    } finally {
      setShowLoader(false);
    }
  };

  const deleteRecord = async (record: any) => {
    try {
      //console.log('Delete record', record);
      setShowLoader(true);
    } catch (error: any) {
      ToastQueue.negative(`Delete record error : ${error.message}`, { timeout: 3000 });
    } finally {
      setShowLoader(false);
    }
  };

  const openSearchDialog = () => {
    setIsOpenSearchContact(true);
    setIsOpenNewContact(false);
  };

  const closeSearchDialog = () => {
    setIsOpenSearchContact(false);
  };

  const openNewContactDialog = () => {
    setIsOpenSearchContact(false);
    setIsOpenNewContact(true);
  };

  const closeNewContactDialog = () => {
    setIsOpenNewContact(false);
  };

  if (showLoader) {
    return (
      <Flex width="100%" justifyContent={'center'} marginTop={10}>
        <ProgressCircle aria-label="Loading…" isIndeterminate />
      </Flex>
    );
  } else {
    return (
      <Flex direction={'column'} gap={'size-150'} position={'relative'} width={'100%'}>
        <Flex direction={'row'} alignItems={'start'} justifyContent={'start'}>
          <Content position={'relative'} UNSAFE_className={styles.heading_text}>
            Client Contact
          </Content>
          <Flex direction={'row'} alignItems={'center'} justifyContent={'center'} gap={'size-100'} UNSAFE_className={styles.icon_add_parent}>
            <Content>
              <Link
                isHidden
                isQuiet
                onPress={e => {
                  setIsOpenSearchContact(true);
                }}
              >
                <i className="bi bi-plus fs-5">
                  <View UNSAFE_className={styles.icon_add_text}>Add</View>
                </i>
              </Link>
            </Content>
          </Flex>
        </Flex>
        <Flex direction={'column'}>
          <Flex maxHeight={{ base: '1000px', L: '450px', M: '450px' }} width="100%" direction="column" UNSAFE_style={{ overflowX: 'auto' }}>
            {externalContacts?.map((contact: any, index: number) => (
              <ContactCard
                key={contact.id}
                contact={contact?.EXTERNAL_CONTACT}
                avatarSrc="../../../../../../assets/images/avatar--desktop--light@2x.png"
                selectedItem={selectedItem}
                FPADataTypes={FPADataTypes}
                onSaveDescription={onSaveDescription}
                onSaveRating={onSaveRating}
              />
            ))}
          </Flex>

          <DialogContainer
            isDismissable
            onDismiss={() => {
              setIsOpenSearchContact(false);
            }}
          >
            {isOpenSearchContact && (
              <Dialog>
                <Header UNSAFE_className={styles.add_contact_heading}>Add Client Contact</Header>
                <Content>
                  <ExternalContactsSearch closeDialog={closeSearchDialog} openDialog={openNewContactDialog} />
                </Content>
              </Dialog>
            )}
          </DialogContainer>

          <DialogContainer
            isDismissable
            onDismiss={() => {
              setIsOpenNewContact(false);
            }}
          >
            {isOpenNewContact && (
              <Dialog>
                <Header UNSAFE_className={styles.new_contact_heading}>New Contact</Header>
                <Content>
                  <ExternalNewContact closeDialog={closeNewContactDialog} openDialog={openSearchDialog} />
                </Content>
              </Dialog>
            )}
          </DialogContainer>
        </Flex>
      </Flex>
    );
  }
}

export const ExternalContactsComponent = LayoutComponent(ExternalContacts);
