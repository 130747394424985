import { BaseRequest, IBaseRequest } from '../../BaseRequest';
import { TimeSheetItem, toTimeSheetItemXML } from '../../dataObjects/TimeSheetItem';

export interface ICreateTimesheetRequest extends IBaseRequest {
    ITEM: TimeSheetItem;
}

export class CreateTimesheetRequest extends BaseRequest implements ICreateTimesheetRequest {
    public ITEM: TimeSheetItem;
    constructor(server: string, session: string, item: TimeSheetItem) {
        super(server, session);
        this.ITEM = item;
    }

    public toXml(): string {
        var parameters: string = super.toXml();

        parameters += '<ITEM>';

        parameters += toTimeSheetItemXML(this.ITEM);
        
        parameters += '</ITEM>';
        return super.wrapperXml('CreateTimesheet', parameters);
    }
}
